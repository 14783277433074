<template>
  <div class="head colorF pad20" style="position: fixed;display: flex;justify-content: space-between;width:100%;">
    <!-- <h2 class="fl logo" v-if="LanguageShow">{{$t('login.loginBox.headertitle')}}</h2> -->
    <img class="fl logo" v-if="LanguageShow" src="@/assets/img/login/htc_cc.svg" style="margin-right: 50px;" />
    <ul class="langBtns">
      <li @click="changeLang('zh-TW')">
        <svg-icon class="icon" :icon-class="$i18n.locale === 'zh-TW' ? 'lang_tw_sel' : 'lang_tw'" />
      </li>
      <li @click="changeLang('zh-CN')">
        <svg-icon class="icon" :icon-class="$i18n.locale === 'zh-CN' ? 'lang_cn_sel' : 'lang_cn'" />
      </li>
      <li @click="changeLang('en-US')">
        <svg-icon class="icon" :icon-class="$i18n.locale === 'en-US' ? 'lang_en_sel' : 'lang_en'" />
      </li>
    </ul>
  </div>
</template>

<script>
import profile from '@/utils/profile'
export default {
  name: '',
  data() {
    return {
      visible: false,
      LanguageShow: true,
      isDevelopment: process.env.NODE_ENV === 'development'
    }
  },
  created() {
    this.languagebtn()
  },
  methods: {
    changeLang(name) { // 切换语言
      // 先判斷是否要更換網頁
      // let rcStr = window.location.hostname.indexOf('-rc') > 0 ? 'rc' : ''
      // if (!this.isDevelopment && name === 'zh-CN' && !this.$jsFn.isCN()) {
      //   window.location.href = profile.domainUrl['cn' + rcStr]
      // } else {
        this.$i18n.locale = name
        localStorage.setItem('local', name)
        if (name === 'zh-CN') {
          this.$Message.success('已切换成中文简体')
        } else if (name === 'zh-TW') {
          this.$Message.success('已切換成中文繁體')
        } else {
          this.$Message.success('Switched to English')
        }
      // }
    },
    languagebtn() {
      let routers = this.$route.path
      if (routers === '/mobilelogin') {
          this.LanguageShow = false
      }
    }
  }
}
</script>

<style scoped>

</style>
