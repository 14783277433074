<template>
    <div class="footer colorF">
        <div style="width:90%;margin:0 auto; padding:20px 0;text-align:center">
            <span style="color:#939393;font-size: 12px;">{{$t('copyright')}}</span>
            <span v-if="$jsFn.isCN()">
                <a target="_blank" href="https://beian.miit.gov.cn" style="text-decoration:none;height:20px;line-height:20px;">
                    <span style="height:20px;line-height:20px;margin: 0px 5px 0px 15px; color:#939393;">蜀ICP备18027363号-1</span>
                </a>
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010402000615" style="text-decoration:none;height:20px;line-height:20px;">
                    <img src="../../assets/img/beian.png" style="" />
                    <span style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">川公网安备 51010402000615号</span>
                </a>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: '',
    data() {
        return {
            visible: false,
            LanguageShow: true,
            isDevelopment: process.env.NODE_ENV === 'development'
        }
    }
}
</script>

<style scoped>

</style>
