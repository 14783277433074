<template>
    <div class="login bodyBG" :style="{background: 'url(' + bgDefa + ') no-repeat 0 0', 'backgroundSize': 'cover'}">
        <div style="bottom: 0;position: absolute;font-size: 12px;color: #919191;right: 0;">{{$store.state.version}}</div>
        <CommonHeader></CommonHeader>
        <div :style="{height: '100%', width: '100%', display: 'flex', 'justify-content': 'center'}">
            <div class="login-box pad20 tc">
                <div class="login-box-cont">
                    <!-- Title -->
                    <img class="titleImg" src="@/assets/img/login/logo.png" />
                    <div style="text-align: left;width: 100%;max-width: 620px;">
                        <!-- 輸入層 -->
                        <div class="loginLayer" :class="lang">
                            <div class="formDiv">
                                <!-- subTitle -->
                                <span class="subTitle">{{$t('login.loginBox.loginWord')}}</span>
                                <Form style="width: 100%;" ref="loginForm" :model="loginForm" :rules="loginRule" :show-message="false" @keydown.enter.native="loginSubmit('loginForm')">
                                    <FormItem class="ItemPadding" prop="loginId" style="text-align: left;margin-bottom: 10px;">
                                        <Input size="large" element-id="loginId" v-model="loginForm.loginId" :placeholder="$t('login.loginBox.loginId')"></Input>
                                    </FormItem>
                                    <FormItem class="ItemPadding" prop="password" style="text-align: left;margin-bottom: 10px;">
                                        <Input size="large" element-id="loginPassword" class="loginPsw" password :type="pwState.type" v-model="loginForm.password" :placeholder="$t('login.loginBox.password')">
                                            <svg-icon class="btn" slot="append" :icon-class="pwState.icon" @on-click="seepw()" />
                                            <svg-icon class="btn" slot="append" icon-class="pw_enter" @on-click="loginSubmit('loginForm')" />
                                        </Input>
                                    </FormItem>
                                </Form>
                                <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
                                <div class="errlable">{{ loginErrText }}</div>
                                    <div class="link">
                                        <span @click="toAccount('regist')">{{$t('login.loginBox.regist')}}</span> | <span @click="toAccount('forogtPw')">{{$t('login.loginBox.forgotPW')}}</span>
                                    </div>
                                </div>
                                <!-- 第三方層 -->
                                <div class="authLayer">
                                    <p class="title">{{$t('login.loginBox.authLoginTitle')}}</p>
                                    <div class="auths">
                                        <template v-if="!$jsFn.isCN()">
                                            <div class="auth" @click="getProviderOauth('facebook')"><img src="@/assets/img/login/auth_facebook.svg"></div>
                                            <div class="auth" @click="getProviderOauth('google')"><img src="@/assets/img/login/auth_google.svg"></div>
                                            <div class="auth" @click="getProviderOauth('educloudtw')"><img src="@/assets/img/login/auth_educloud.svg"></div>
                                        </template>
                                        <template v-else>
                                            <div class="auth" @click="getProviderOauth('ding')"><img src="@/assets/img/login/auth_dingtalk.svg"></div>
                                            <!-- 微信開放與否要詢問 <div class="auth"><img src="@/assets/img/login/auth_wechat.svg"></div> -->
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="qrcodeDiv" >
                                <div id="qrcode" :class="{'qrcode': joinQRcode != undefined}" ref="qrcode"></div>
                                <div class="expired" v-if="joinQRcode != undefined && !sseSurvive">
                                    <Icon size="30" type="md-refresh-circle" @click="SSELink()" />
                                </div>
                                <span class="remark">{{$t('login.loginBox.qrcode.text1')}}<a target="_blank" :href="hita5download">{{$t('login.loginBox.qrcode.text2')}}</a>{{$t('login.loginBox.qrcode.text3')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CommonFooter></CommonFooter>
        <!--浏览器检测-->
        <q-dialog v-model="confirm" persistent>
            <q-card>
                <q-card-section class="row items-center">
                    <span class="q-ml-sm"><span class="remind">{{$t('remind.title')}}：<br/></span><span class="remindtext">{{$t('remind.content')}}</span></span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat :label="$t('common.cancel')" color="primary" v-close-popup />
                    <q-btn flat :label="$t('remind.toDownload')" color="primary" @click="todownload" />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <!--浏览器检测end-->
    </div>
</template>
<script>
    import bgDefault from '@/assets/img/login/bg_cloud.jpg'
    import CommonHeader from '@/components/Common/CommonHeader.vue'
    import CommonFooter from '@/components/Common/CommonFooter.vue'
    import profile from '@/utils/profile'
    import QRCode from 'qr-code-styling'
    import { EventSourcePolyfill } from 'event-source-polyfill'
    import qrcodeImg from '@/assets/img/login/hbk_tmd.svg'
    const de = require('@/utils/lib1.js')
    import enc from '@/utils/enc.js'
    import { mapGetters } from "vuex";

    export default {
        components: {
            CommonHeader,
            CommonFooter
        },
        data() {
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error())
                    this.loginErrText = this.$t('error.required')
                } else if (value.search(/^\+/) === 0) {
                    callback(new Error())
                    this.loginErrText = this.$t('error.format.default')
                } else {
                    callback()
                    this.loginErrText = ''
                }
            }
            const validatePasswordCheck = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error())
                    this.loginErrText = this.$t('error.required')
                } else {
                    callback()
                    this.loginErrText = ''
                }
            }
            return {
                loginErrText: '',
                bgDefa: bgDefault,
                joinQRcode: undefined,
                sseSurvive: false,
                redirectUrlByThird: document.location.origin + '/',
                esState: '',
                loading: false,
                loginForm: {
                    loginId: '',
                    password: ''
                },
                loginRule: {
                    loginId: [
                            { validator: validatePass, trigger: 'blur' }
                        ],
                    password: [
                            { validator: validatePasswordCheck, trigger: 'blur' }
                        ]
                },
                callback: window.location.href,
                tokenRes: {},
                token: '',
                loginBack: {
                    ticket: '',
                    name: '',
                    teamModelId: ''
                },
                confirm: false,
                pwVisibility: false,
                blobConnect: {
                    name: '',
                    sas: '',
                    sas_write: '',
                    sas_read: '',
                    url: '',
                    id:''
                },
                loginType: 'normal', // 一般登入: normal, 掃瑪登入: scan
                ies5CrtLesson: { cid: '', sid: '', rid: '', school: '', sp: '', rp: '', cname: '', sname: '', schoolname: '' }
            }
        },
        computed: {
            ...mapGetters({
                getFunc: "funcs/getFunc",
                getFuncAll: "funcs/getFuncAll"
            }),
            pwState: function(){
                if(this.pwVisibility){
                    return {
                        type: 'text',
                        icon: 'pw_visibility'
                    }
                } else {
                    return{
                        type: 'password',
                        icon: 'pw_invisibility'
                    }
                }
            },
            hita5download: function(){
                let url
                switch (this.$i18n.locale.toLowerCase()) {
                    case 'zh-tw':
                        url = 'https://www.habook.com/zh-tw/download.php?act=view&id=68'
                        break;
                    case 'zh-cn':
                        url = 'https://www.habook.com.cn/download.php?act=view&id=54'
                        break;
                    default:
                        url = 'https://www.habook.com/en/download.php?act=view&id=68'
                        break;
                }
                return url
            },
            lang: function(){
                switch (this.$i18n.locale) {
                    case 'zh-TW':
                        return 'tw'
                    case 'zh-CN':
                        return 'cn'
                    case 'en-US':
                        return 'en'
                }
            }
        },
        methods: {
            // showLoading
            showLoading(msgss) {
                this.$q.loading.show({
                    message: msgss
                })
            },
            toAccount(event) {
                let station = this.$jsFn.isCN() ? 'cn' : 'global'
                let path = event === 'regist' ? '/regist' : '/forgetpassword'
                window.location.href = profile.accountUrl[station] + path + '?callback=' + window.location.href
            },
            seepw(){
                this.pwVisibility = !this.pwVisibility
            },
            // 获取URL地址的指定参数
            getParamAsCH(paramName) {
                var paramValue = ''
                var isFound = false
                if (window.location.hash.indexOf('?') == 2 && window.location.hash.indexOf('=') > 1) {
                    var arrSource = decodeURI(window.location.hash).substring(1, window.location.hash.length).split('&')
                    var i = 0
                    while (i < arrSource.length && !isFound) {
                        if (arrSource[i].indexOf('=') > 0) {
                            if (arrSource[i].split('=')[0].toLowerCase() == paramName.toLowerCase()) {
                                paramValue = arrSource[i].split('=')[1]
                                isFound = true
                            }
                        }
                        i++
                    }
                }
                paramValue = unescape(paramValue)
                return paramValue
            },
            // 回调处理
            loginCallback() {
                let code = this.$jsFn.isEmpty(this.$route.query.code) ? '' : this.$route.query.code
                let state = this.$jsFn.isEmpty(this.$route.query.state) ? '' : this.$route.query.state
                this.ies5CrtLesson.cid = !!this.$route.query.cid ? this.$route.query.cid : ''
                this.ies5CrtLesson.sid = !!this.$route.query.sid ? this.$route.query.sid : ''
                this.ies5CrtLesson.rid = !!this.$route.query.rid ? this.$route.query.rid : ''
                this.ies5CrtLesson.school = !!this.$route.query.school ? this.$route.query.school : ''
                this.ies5CrtLesson.sp = !!this.$route.query.sp ? this.$route.query.sp : ''
                this.ies5CrtLesson.rp = !!this.$route.query.rp ? this.$route.query.rp : ''
                this.ies5CrtLesson.cname = !!this.$route.query.cname ? this.$route.query.cname : ''
                this.ies5CrtLesson.sname = !!this.$route.query.sname ? this.$route.query.sname : ''
                this.ies5CrtLesson.schoolname = !!this.$route.query.schoolname ? this.$route.query.schoolname : ''

                if (!this.$jsFn.isEmpty(code) && !this.$jsFn.isEmpty(state)) {
                    this.putUserCode(code, state)
                } else if (!this.$jsFn.isEmpty(code)) {
                    this.codeLogin(code)
                } else {
                    this.SSELink() // 啟動Qrcode
                }                
            },
            //new blob-auth
            async blobAuth() {
                this.showLoading(this.$t('loadingMsg.text1'))

                let TMDid = this.$jsFn.jwtDecode(sessionStorage.getItem('idToken'))
                let userid = TMDid.sub

                // 檢查TMID 的訊息通用語系
                if(TMDid.lang == null || TMDid.lang === undefined) {
                    await this.$api.coreApi.setDefNoticeLang(sessionStorage.getItem('idToken'), this.$i18n.locale.toLowerCase()).then(res => {
                        console.log(res, 'res')
                    })
                }

                // 重置個人Temp
                await this.$api.ies5Api.resetBlob().then(res => {
                    console.log(res, 'resetBlob')
                })

                // mqtt 裝置註冊
                await this.$api.coreApi.regDevice(sessionStorage.getItem('idToken') , {id: userid, version: this.$store.state.version}).then( (res) => {
                    if(!!res){
                        this.$store.dispatch('mqtt/setDeviceInfo', res)
                    }
                })

                // 取得個人活動獎勵
                await this.$api.coreApi.getContact(sessionStorage.getItem('idToken')).then(res => {
                    // 測試用
                    // res.hiteachcc = [
                    //  { func: 'works', para: 2, get: '1646120382', exp: '1646120382' }, // 作品收集任務數
                    //  { func: 'clients', para: 11, get: '1646120382', exp: '1646120382' }, // 連線人數 (掃碼改為50)
                    //  { func: 'pageqty', para: 60, get: '1646120382', exp: '1646120382'} // 頁面數
                    // ]

                    let tmp = []
                    let hiCC = []
                    let hiTh = []

                    if(!!res.hiteachcc){
                        hiCC = res.hiteachcc
                    }

                    if(!!res.hiteach){
                        // 配合活動只針對aigpt確認
                        let i = res.hiteach.findIndex((e) => e.func == 'aigpt');
                        if(i != -1){
                            hiTh.push(res.hiteach[i])
                        }
                    }

                    tmp = hiCC.concat(hiTh)

                    if(tmp.length > 0){
                        this.$store.dispatch('funcs/setup', tmp)
                    }
                })

                // 取得個人授權
                await this.$api.bbApi.getTMIDService().then(res => {
                    // 配合活動只針對aigpt確認
                    const index = res.findIndex((e) => e.prodCode == 'Z6ELB6EZ');
                    if(index != -1 && !!res[index]?.aprule['aigpt']){
                        this.$store.dispatch('funcs/setup', [{ func: 'aigpt', para: null, get: res[index].startDate, exp: res[index].endDate }])
                    }
                })


                // 取得個人偏好設定
                await this.$api.coreApi.getSettings(sessionStorage.getItem('idToken')).then(res => {
                    if(!!res.data.pref){
                        this.$store.dispatch('preferences/setProfilePREF', res.data)
                    }
                })
                
                this.loginErrText = ''
                await this.$api.ies5Api.getUserBlob().then(res => {
                    console.log(res, '成功blob')
                    console.log(res.blob_sas, 'blob_sas')
                    // 儲存blobConnect
                    this.blobConnect.sas = ''
                    this.blobConnect.sas_write = res.blob_sas_write,
                    this.blobConnect.sas_read = res.blob_sas_read,
                    this.blobConnect.url = res.blob_uri
                    this.$store.dispatch('classInfo/init', {blob: this.blobConnect})
                }).catch(res => {
                    this.loginErrText = this.$t('login.formMsg.error1')
                    console.log(res, '失败blob')
                })

                // 創建教室取得編號
                await this.$api.coreApi.crtChannel({
                    client_name: 'HiTeachCC',
                    user_id: userid,
                    pin: '8888',
                    local_ip: '',
                    channel: '',
                    school_code: this.ies5CrtLesson.school ? this.ies5CrtLesson.school : ''
                }).then(res => {
                    sessionStorage.setItem('classNum', res.channel_num)
                    sessionStorage.setItem('external_ip', res.ip)
                }).catch(err=>{
                    console.log(err)
                   this.loginErrText = 'created class faild'
                })

                // IES5 設定
                var isFalsy = Object.entries(this.ies5CrtLesson).every(([key, value]) =>(key == 'schoolname' && value == '') || (key == 'rp' && value == '') || (key == 'rid' && value == '') || (key == 'cname' && value == '') || (key == 'sname' && value == '') || !!value)
                if(isFalsy){
                    // 詢問IES5取得上課資訊
                    sessionStorage.setItem('ies5CrtLesson', JSON.stringify(this.ies5CrtLesson)) // 把IES5的開課資訊存起來
                    this.ies5CrtLesson.channel = sessionStorage.getItem('classNum')
                    await this.$api.ies5Api.getClassInfo(this.ies5CrtLesson).then(res => {
                        this.$store.dispatch('classInfo/setupByIES5', res)
                        // 權益
                        if(!!res.funcs){
                            this.$store.dispatch('funcs/setup', res.funcs)
                        }
                    })
                }

                // 如果沒錯誤再跳至白板頁
                if(this.loginErrText == '') {
                     // 儲存User資訊
                    var jwtUser = this.$jsFn.jwtDecode(sessionStorage.getItem('idToken'))
                    sessionStorage.setItem('user', JSON.stringify(jwtUser))

                    // 儲存blobConnect
                    this.blobConnect.id = jwtUser.sub
                    this.blobConnect.name = jwtUser.name
                    sessionStorage.setItem('blobConnect', enc.encrypt(JSON.stringify(this.blobConnect), de.text))

                     // 儲存loginBack
                    let serverLocation = this.$jsFn.isCN() ? 'cn' : 'global'
                    let clientID = profile.clientID[serverLocation]
                    let backdata = {
                        backCode: '', // 先給空白有需要再調整
                        backClientId: clientID
                    }
                    sessionStorage.setItem('loginBack', JSON.stringify(backdata))
                    this.$router.push('/Board').then(res=>{
                        this.$q.loading.hide()
                    })
                } else {
                    this.$q.loading.hide()
                }
            },

            //判断终端
            _isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                return flag;
            },
            //判断浏览器
            browser() {
                var isChrome = navigator.userAgent.indexOf('Chrome') > -1  // 是否是谷歌 
                if (this.$jsFn.isCN() && !isChrome) { this.confirm = true} // 改為只有cn 站才會判斷
                console.log('谷歌' + isChrome)
            },
            // Google 下載位置
            todownload() {
                //alert('点击了确定')
                let locale = this.$i18n.locale.toLowerCase()
                switch (locale) {
                    case 'zh-tw':
                        window.open('https://www.google.com.tw/intl/zh-TW/chrome/')
                        break;
                    case 'zh-cn':
                        window.open('https://www.google.cn/intl/zh-CN/chrome/')
                        break;
                    default:
                        window.open('https://www.google.com/intl/en-US/chrome/')
                        break;
                }
                this.confirm = false
            },
            // 第三方跳轉
            getProviderOauth: function(provider) {
                window.location.href = profile.communityUrl(provider, this.redirectUrlByThird)
            },
            // 登入檢查
            loginSubmit(name) {
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        this.showLoading(this.$t('loadingMsg.text2'))
                        this.$api.coreApi.login({
                            id: this.loginForm.loginId,
                            pass: this.loginForm.password
                        }).then(res => {
                            console.log(res, 'loginSubmit-res')
                            this.logining(res)
                        }).catch(e => {
                            console.log(e, 'error')
                            this.loginErrText = this.$t('login.formMsg.error2')
                            this.$q.loading.hide()
                        })
                    }
                })
            },
            SSELink: function() { // 開啟SSE連結
                if (!this.sseSurvive) {
                    let apiUrl = profile.getApiUrl()
                    let es = new EventSourcePolyfill(apiUrl + '/service/sse', { headers: { 'X-Auth-Name': 'HitachCC' } })
                    let _this = this
                    this.esState = es

                    var closeSSE = function() {
                        if (_this.sseSurvive) {
                            es.close()
                            _this.sseSurvive = false
                        }
                    }

                    es.addEventListener('open', function(e) {
                        _this.sseSurvive = true
                    }, false)

                    // 取得登入網址
                    es.addEventListener('message', function(e) {
                        if (e.data) {
                            let data = JSON.parse(e.data)
                            if (data.sid) {
                                let qrcodeURL = apiUrl + '/qrcode/qrcodelogin?sid=' + encodeURIComponent(data.sid, 'utf-8') + '&info=' + encodeURIComponent('TEAM Model', 'utf-8') + '&m=' + encodeURIComponent(_this.$t('login.loginBox.qrcode.text4'), 'utf-8')
                                _this.crtQrcode(qrcodeURL)
                            } else if (data.code) {
                                closeSSE()
                                _this.loginType = 'scan'
                                if(data.type && data.type === 'HiTA3'){
                                    _this.$Message.error(_this.$t('login.formMsg.error5'));
                                } else {
                                    _this.codeLogin(data.code)
                                }
                            }
                        }
                    }, false)

                    // 錯誤
                    es.addEventListener('error', function(e) {
                        closeSSE()
                    }, false)

                    // 設定五分鐘後關閉連接
                    setTimeout(() => {
                        closeSSE()
                    }, 300000)
                }
            },
            crtQrcode: function(url) { // 開啟QRCODE
                this.$nextTick(() => {
                    let QRCodeOption = {
                            'width':150,
                            'height':150,
                            'data': url,
                            'margin':0,
                            'padding':0,
                            'type': 'canvas',
                            'image': qrcodeImg,
                            'qrOptions':{'typeNumber':'0','mode':'Byte','errorCorrectionLevel':'Q'},
                            'imageOptions':{'hideBackgroundDots':false,'imageSize':0.6,'margin':0},
                            'dotsOptions':{'type':'classy','color':'#5b6d77'},
                            'backgroundOptions':{'color': '#fff'},
                            'dotsOptionsHelper':{'colorType':{'single':true,'gradient':false},
                            'gradient':{
                                'linear':true,
                                'radial':false,
                                'color1':'#6a1a4c',
                                'color2':'#6a1a4c',
                                'rotation':'0'}
                            },
                            'cornersSquareOptions':{'type':'dot', 'color':'#5b6d77'},
                            'cornersSquareOptionsHelper':{
                                'colorType':{'single':true,'gradient':false},
                                'gradient':{'linear':true,'radial':false,'color1':'#000000','color2':'#000000','rotation':'0'}
                            },
                            'cornersDotOptions':{'type':'dot','color':'#5b6d77'},
                            'cornersDotOptionsHelper':{
                                'colorType':{'single':true,'gradient':false},
                                'gradient':{'linear':true,'radial':false,'color1':'#000000','color2':'#000000','rotation':'0'}
                            },
                            'backgroundOptionsHelper':{
                                'colorType':{'single':true,'gradient':false},
                                'gradient':{'linear':true,'radial':false,'color1':'#ffffff','color2':'#ffffff','rotation':'0'}
                            }
                        }
                    if (this.joinQRcode === undefined) {
                        let qrcode = new QRCode(QRCodeOption)
                        this.joinQRcode = qrcode
                        this.joinQRcode.append(document.getElementById('qrcode'))
                    } else {
                        this.joinQRcode.deleteExtension()
                        this.joinQRcode.update(QRCodeOption)
                    }
                })
            },
            putUserCode: function(code, state) { // 如果第三方登入會啟動的function
                this.showLoading(this.$t('loadingMsg.text2'))
                this.$api.coreApi.authLogin({
                    grant_type: state,
                    open_code: code,
                    redirect_uri: this.redirectUrlByThird,
                    lang: this.$i18n.locale.toLowerCase()
                }).then(res => {
                    this.logining(res)
                }).catch(() => {
                    this.loginErrText = this.$t('login.formMsg.error3', { 'state': state })
                    this.$router.push('/')
                    this.$q.loading.hide()
                })
            },
            codeLogin: function(code) {
                this.showLoading(this.$t('loadingMsg.text2'))
                this.$api.coreApi.codeLogin(code).then(res => {
                    this.logining(res)
                }).catch(e => {
                    console.log(e, 'error')
                    this.loginType = 'normal' // 錯誤後回復成一般登入
                    this.loginErrText = this.$t('login.formMsg.error4')
                    this.$q.loading.hide()
                })
            },
            closeSSE() {
                if (this.sseSurvive) {
                    this.esState.close()
                    this.sseSurvive = false
                }
            },
            // 登入處理
            logining(data) {
                let local = localStorage.getItem('local')
                sessionStorage.clear()
                localStorage.setItem('local', local)
                sessionStorage.setItem('idToken', data.id_token)
                sessionStorage.setItem('access_token', data.access_token)
                sessionStorage.setItem('expires_in', data.expires_in)
                sessionStorage.setItem('token_type', data.token_type)
                sessionStorage.setItem("loginByScan",false)

                // exp 是直接給一個大於現在的值，保持後端計算是透過時間計算
                let nT = parseInt(Date.parse(new Date())/1000) // 用十碼計算
                let yearLater = nT + 3153600000

                // 針對掃瑪登入調整
                if (this.loginType === 'scan') {
                    sessionStorage.setItem("loginByScan",true)
                    this.$store.dispatch('funcs/setup', [
                        { func: 'works', para: 6, get: nT, exp: yearLater }, // 作品收集任務數
                        { func: 'clients', para: 50, get: nT, exp: yearLater }, // 連線人數
                    ])
                }
                this.blobAuth()
            }
        },
        mounted() {
            this.loginCallback()
            // if (this._isMobile()) {
            //     this.$router.push('/mobilelogin')
            // } else {
            // }
            // this.browser()
            // 保留以下定時更換背景圖功能
            // setInterval(() => {
            //     if(this.bgDefa === bgDefault){
            //         this.bgDefa = MUJI03
            //     } else {
            //         this.bgDefa = bgDefault
            //     }
            // }, 7000)
        }
    }
</script>
<style lang="less">
    #loginId{
        border-color: #6c7a89;
        &::placeholder{
            color: #b4bbc2;
        }
    }
    #loginPassword{
        border-color: #6c7a89;
        border-right: 0;
        &::placeholder{
            color: #b4bbc2;
        }
    }
    .loginPsw{
        .ivu-input-group-append {
            border-color: #5b6d77;
            background-color: #fff;
        }
        .btn {
            margin-right: 10px;
            width: 17px;
            height: 17px;
            cursor: pointer;
        }
    }
</style>
<style scoped>
    .errlable{
        text-align: left;
        color: #f22613;
        font-size: 12px;
        width: 100%;
    }
    .login-studentbtn {
        margin-top: 20%;
        display: inline-block;
        text-align: center;
        background: #fff;
        height: 42px;
        line-height: 42px;
        color: #666;
        font-size: 14px;
        border-radius: 6px;
        width: 55%;
        cursor: pointer;
        position: relative;
    }

    @media only screen and (max-width: 768px) {
        h2 {
            line-height: 2.5rem;
        }
    }
    .remind {
        font-size: 22px;
        font-weight: bold;
        color: #3366FF;
    }
    .remindtext {
        font-size:14px;
    }
</style>
